import React, { useState, useEffect } from "react";
import gg from "../staticAssets/gg.gif";
import { DebounceInput } from "react-debounce-input";
import { places } from "../services/apiServices";
import i from "../staticAssets/user.png";

const currentDate = () => {
  let yourDate = new Date();
  const offset = yourDate.getTimezoneOffset();
  yourDate = new Date(yourDate.getTime() - offset * 60 * 1000);
  return yourDate.toISOString().split("T")[0];
};
const currentTime = () => {
  let yourDate = new Date();
  const offset = yourDate.getTimezoneOffset();
  yourDate = new Date(yourDate.getTime() - offset * 60 * 1000);
  let t = yourDate.toISOString().split("T")[1].split(".")[0];
  return t;
};

function UserDetailModal({ data }) {
  const [query, setquery] = useState("");
  const [placeList, setplaceList] = useState([]);
  const [currentPlace, setcurrentPlace] = useState({});
  const [placeInputFocus, setplaceInputFocus] = useState(false);
  const [timeOfBirth, settimeOfBirth] = useState("");
  const [dob, setdob] = useState("");

  const handleSubmit = async() => {
    let ob = {
      timeOfBirth: timeOfBirth,
      dob: dob,
      latitude: currentPlace.latitude,
      longitude: currentPlace.longitude,
      placeOfBirth: currentPlace.place
    }
    await data?.initiateByAlert(ob)
  }

  useEffect(() => {
    const f = async () => {
      let res = await places({ key: query });
      setplaceList([...res.payLoad.places]);
    };
    f();
  }, [query]);


  useEffect(() => {
    if(data.user.timeOfBirth) {
      settimeOfBirth(data.user.timeOfBirth);
    }
    else {
      settimeOfBirth(currentTime())
    }

    if(data.user.dob){
      let dob = data.user.dob;
      dob = new Date(dob);
      dob = [dob.getFullYear(), ((dob.getMonth()+1)+"").padStart(2,'0'), (dob.getDate()+"").padStart(2,'0')].join('-');
      setdob(dob);
    }
    else {
      setdob(currentDate())
    }
    if(data.user.placeOfBirth && data.user.latitude && data.user.longitude) {
      let p = currentPlace;
      p.place = data.user.placeOfBirth;
      p.latitude = data.user.latitude;
      p.longitude  = data.user.longitude;
      setcurrentPlace({...p});
    }


  }, [data.user]);


  return (
    <div
      className="fixed z-50 inset-0 overflow-y-auto worksans"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <div className="flex items-end justify-center h-screen pt-4 px-0 md:px-4 pb-0 md:pb-20 text-center sm:block">
        <div
          className="fixed inset-0 bg-black bg-opacity-80 transition-opacity"
          aria-hidden="true"
        ></div>
        <span
          className="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
        >
          &#8203;
        </span>

        <div className="inline-block align-bottom bg-white rounded-lg text-left shadow-xl transform transition-all sm:my-8 sm:align-middle w-full md:w-3/4 lg:w-2/3">
          
          <div className="bg-white px-4 py-3 sm:px-6 flex flex-col max-h-screen items-center w-full rounded-xl pt-10 relative">
            <button className="text-base md:text-2xl p-4 absolute right-0 md:-right-12 top-0 text-black md:text-white font-light" onClick={()=>{data.setalert(false)}}>X</button>
            <div className="w-full h-20 flex justify-center items-center relative-top w-full">
              <div className="h-24 w-24 rounded-full absolute left-1/2 -translate-x-20 border border-white shadow-sm"> 
              <img loading="lazy" className="rounded-full" src={data?.imgUrl? data?.imgUrl: i}/></div>
              <div className="worksans h-24 w-24 rounded-full absolute right-1/2 translate-x-20 bg-blue-100 border border-white shadow-md text-gray-400 flex justify-center items-center text-3xl">{data?.user?.name.slice(0,1).toUpperCase()}</div>
            </div>
            <h4 className="text-xl font-semibold mt-4">
              Save upto ₹ 10 on this call/chat
            </h4>
            <h6 className="text-sm text-gray-500 my-2 w-11/12 md:w-2/3 text-center">
              We suggest you share basic details with astro to create your
              kundli chart and save your precious time and money.
            </h6>
            <div className="flex flex-col md:flex-row w-full my-4 gap-4">
              <div className="w-full md:w-1/2 flex flex-col">
                <h4 className="my-1">Your Date of Birth</h4>
                <div className="border w-full h-12 md:h-16 rounded-xl bg-gray-100/70 flex items-center px-4">
                  <input
                    className="focus:outline-none w-full text-sm md:text-lg bg-gray-100/70"
                    type="date"
                    value={dob}
                    onFocusCapture={(e)=> {setplaceInputFocus(false)}}
                    onChange={(e)=>{setdob(e.target.value)}}
                    />
                </div>
              </div>
              <div className="w-full md:w-1/2 flex flex-col">
                <h4 className="my-1">Your Time of Birth</h4>
                <div className="border w-full h-12 md:h-16 rounded-xl bg-gray-100/70 flex items-center px-4">
                  <input
                    className="focus:outline-none w-full text-sm md:text-lg bg-gray-100/70"
                    type="time"
                    value={timeOfBirth}
                    onFocusCapture={(e)=> {setplaceInputFocus(false)}}
                    onChange={(e)=>{settimeOfBirth(e.target.value)}}
                  />
                </div>
              </div>
            </div>
            <div className="w-full flex flex-col ">
              <h4 className="my-1">Place of Birth</h4>
              <div className="border w-full h-12 md:h-16 rounded-xl bg-gray-100/70 flex items-center px-4 relative">
                {/* <input className="focus:outline-none w-full text-sm md:text-lg bg-gray-100/70" type="text" placeholder="Enter Birth Place"/> */}
                <DebounceInput
                  className="focus:outline-none w-full text-sm md:text-lg bg-gray-100/70"
                  minLength={1}
                  debounceTimeout={300}
                  value={currentPlace.place}
                  onChange={(event) => {
                    let p = {
                        place: event.target.value
                    }
                    setcurrentPlace({...p})
                    setquery(event.target.value)}}
                  type="text"
                  placeholder="Enter Birth Place"
                  onFocusCapture={(e)=> {setplaceInputFocus(true)}}
                />
              </div>
              {placeList.length > 0 && placeInputFocus && <div className="absolute -bottom-0 flex flex-col bg-white rounded-lg shadow text-sm p-2">
                    {placeList.map((place, i) =>{
                        return <a onClick={()=>{setcurrentPlace({...place}); setquery("")}} className="my-1 cursor-pointer hover:text-orange-600" key={i}>
                            {place.place}
                        </a>
                    })}
              </div>}
            </div>
            <button className="w-2/5 py-2 font-semibold text-white bg-[#FB5B1D] mt-8 rounded-xl disabled:bg-gray-100 disabled:text-gray-500" disabled={!(timeOfBirth && currentPlace?.latitude && dob)} onClick={async()=>{await handleSubmit(); data?.setalert(false)}}>Continue</button>
            <a className="cursor-pointer text-xs my-4" onClick={async()=>{await data?.initiateByAlert(); data?.setalert(false);}}>Skip, I’ll Share on a Chat/Call</a>
            {/* <button type="button" className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm">
                            Deactivate
                        </button> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default UserDetailModal;
