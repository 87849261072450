import React, { useState, useMemo, useEffect } from "react";
import "react-phone-number-input/style.css";
import PhoneInput, { parsePhoneNumber, getCountryCallingCode } from "react-phone-number-input";
import a from "../staticAssets/chakra.png";
import Dropdown from "./Dropdown";

const numOfFields = 4;

const useSSNFields = () => {
  const [ssnValues, setValue] = React.useState({
    ssn1: "",
    ssn2: "",
    ssn3: "",
    ssn4: "",
  });
  const joinSsn = () => {
    return ssnValues.ssn1 + ssnValues.ssn2 + ssnValues.ssn3 + ssnValues.ssn4;
  };

  return {
    handleChange: (e) => {
      const { maxLength, value, name } = e.target;
      const [fieldName, fieldIndex] = name.split("-");

      // Check if they hit the max character length
      if (value.length >= maxLength) {
        // Check if it's not the last input field
        if (parseInt(fieldIndex, 10) < 4) {
          // Get the next input field
          const nextSibling = document.querySelector(
            `input[name=ssn-${parseInt(fieldIndex, 10) + 1}]`
          );

          // If found, focus the next field
          if (nextSibling !== null) {
            nextSibling.focus();
          }
        }
      }

      setValue({
        ...ssnValues,
        [`ssn${fieldIndex}`]: value,
      });
    },
    otp: joinSsn(),
  };
};

function LoginModal({
  setshowLogin,
  loginUser,
  verifyUser,
  update,
  ipDetails,
}) {
  const [value, setValue] = useState("");
  const [check, setcheck] = useState(true);
  const [verifyOTP, setverifyOTP] = useState(false);
  const [name, setname] = useState("");
  const [gender, setgender] = useState("");
  const [screen, setscreen] = useState(1);
  const { handleChange, otp } = useSSNFields();
  const [countryCode, setcountryCode] = useState(ipDetails?.country??"IN")

  const handleSendOTP = async () => {
    let d = parsePhoneNumber(value);
    let countryCode = "+" + d.countryCallingCode;
    let phoneNumber = d.nationalNumber;
    let r = await loginUser(countryCode, phoneNumber, check);
    if (r) {
      setverifyOTP(true);
    }
  };

  const handleVerifyOTP = async () => {
    let d = parsePhoneNumber(value);
    let countryCode = "+" + d.countryCallingCode;
    let phoneNumber = d.nationalNumber;
    let r = await verifyUser(countryCode, phoneNumber, otp);
    if (r) {
      setscreen(2);
    }
  };

  const parsePhoneNumberToString = () => {
    let d = parsePhoneNumber(value);
    let countryCode = "+" + d.countryCallingCode;
    let phoneNumber = d.nationalNumber;
    return countryCode + " " + phoneNumber;
  }
  const handleUpdate = async (e) => {
    e.preventDefault();
    if (name !== "" && gender !== "") {
      let p = {
        name: name,
        gender: gender,
      };
      await update(p);
      setshowLogin(false);
    }
  };

  return (
    <div
      className="fixed z-50 inset-0 overflow-y-auto worksans"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <div className="flex items-center justify-start md:justify-center min-h-screen pt-4 px- pb-4 text-center sm:p-1">
        <div
          className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
          aria-hidden="true"
        ></div>
        <div className="bg-white rounded-2xl text-left overflow-hidden shadow-xl transform transition-all sm:my-4 align-middle w-screen md:w-3/4 lg:w-2/3">
          <div className="h-full bg-white flex flex-col lg:flex-row static">
            <button
              onClick={() => {
                setshowLogin(false);
              }}
            >
              <h1 className="absolute top-5 right-5 text-white lg:text-[#FB5B1D]">
                X
              </h1>
            </button>
            <div className="h-40 md:h-40 lg:h-full w-full lg:w-1/3 bg-gradient-to-r from-[#FA7424] to-[#F98928] flex flex-col">
              <h1 className="text-2xl md:text-3xl lg:text-4xl text-white font-semibold ml-4 md::ml-6 lg:ml-6 mt-6 lg:mt-10">
                Login
              </h1>
              <h1 className="text-base md:text-lg lg:text-xl text-white fold-semibold ml-4 md::ml-6 lg:ml-6 mt-2 lg:mt-4 mr-6">
                100% Private Consults, Personalised Remedies, Daily Horoscope
                and More
              </h1>
              <div className="w-0 md:w-full lg:w-full mt-24 h-0 md:h-0 lg:h-64 invisible md:invisible lg:visible">
                <img
                  loading="lazy"
                  className="mt-0 lg:mt-28 opacity-50"
                  src={a}
                ></img>
              </div>
            </div>
            <div className="flex flex-col overflow-hidden w-full">
              {screen == 2 && (
                <>
                  <h1 className="text-2xl md:text-3xl lg:text-4xl font-semibold mt-10 ml-8">
                    What should we call you?
                  </h1>
                  <h1 className="text-base md:text-lg lg:text-xl text-gray-500 mt-4 ml-8">
                    Please share basic detail to start here
                  </h1>
                  <input
                    type="text"
                    className="appearance-none border rounded w-11/12 py-2 px-3 text-gray-700 focus:outline-none focus:shadow-outline ml-8 w-10/12 mt-10"
                    value={name}
                    onChange={(e) => {
                      setname(e.target.value);
                    }}
                    placeholder="Name"
                  />
                  <Dropdown data={{ gender, setgender }} />
                  <div className="w-10/12 flex flex-col  ml-8 mt-6 items-center">
                    <button
                      disabled={name == "" || gender == ""}
                      className="w-full bg-[#FB5B1D] disabled:bg-gray-200 rounded-2xl text-white py-3 text-xl font-semibold mb-6"
                      onClick={handleUpdate}
                    >
                      Continue
                    </button>
                    {/* <button className="mt-2 text-[#FB5B1D] text-xs" onClick={() =>{setscreen(2);}}>Skip</button> */}
                  </div>
                </>
              )}
              {screen == 1 && (
                <>
                  <h1 className="text-2xl md:text-3xl lg:text-4xl font-semibold mt-10 ml-8">
                    Enter your mobile number
                  </h1>
                  {verifyOTP && (<h1 className="text-base md:text-lg lg:text-xl text-gray-500 mt-4 ml-8">
                    You will receive OTP on {parsePhoneNumberToString()}
                  </h1>)}
                  {!verifyOTP && (<h1 className="text-base md:text-lg lg:text-xl text-gray-500 mt-4 ml-8">
                    You will receive OTP on this number
                  </h1>)}
                  {!verifyOTP && (
                    <>
                      <h1 className="text-base md:text-lg lg:text-xl text-gray-500 mt-10 ml-8">
                        Mobile Number
                      </h1>
                      <div className="w-10/12 ml-8 mt-4 border border-gray-200 rounded-lg p-2 flex">
                        <h2 className="mr-2">{"+"+getCountryCallingCode(countryCode)}</h2>
                        <PhoneInput
                          id="phone-input-custom"
                          placeholder="Enter phone number"
                          defaultCountry={ipDetails.country}
                          value={value}
                          countryCallingCodeEditable={true}
                          onChange={setValue}
                          style={{focus_outline: 'none'}}
                          onCountryChange={(e)=>{setcountryCode(e)}}
                        />
                      </div>
                      <button
                        className="w-10/12 ml-8 mt-6 bg-[#FB5B1D] rounded-2xl text-white py-3 text-xl font-semibold"
                        onClick={handleSendOTP}
                      >
                        Send OTP
                      </button>
                      <div className="flex w-10/12 ml-8 lg:ml-16 mt-6">
                        <input
                          className="h-4 w-4 mt-2 accent-gray-500"
                          type="checkbox"
                          value="true"
                          defaultChecked={true}
                          onChange={() => {
                            setcheck(!check);
                          }}
                        />
                        <div className="flex flex-col w-10/12 mb-8">
                          <h1 className="ml-2 text-gray-500">
                            {" "}
                            By providing phone number, you are agreeing to
                            Bodhi’s Terms & Conditions and getting communication
                            on WhatsApp
                          </h1>
                        </div>
                      </div>
                    </>
                  )}
                  {verifyOTP && (
                    <>
                      <div
                        id="otp"
                        className="flex flex-row justify-center text-center px-2 mt-5"
                      >
                        {" "}
                        <input
                          className="m-2 border h-10 w-10 text-center form-control rounded focus:outline-none"
                          type="text"
                          id="third"
                          maxLength="1"
                          name="ssn-1"
                          onChange={handleChange}
                          autoComplete="off"
                        />{" "}
                        <input
                          className="m-2 border h-10 w-10 text-center form-control rounded focus:outline-none"
                          type="text"
                          id="fourth"
                          maxLength="1"
                          name="ssn-2"
                          onChange={handleChange}
                          autoComplete="off"
                        />{" "}
                        <input
                          className="m-2 border h-10 w-10 text-center form-control rounded focus:outline-none"
                          type="text"
                          id="fifth"
                          maxLength="1"
                          name="ssn-3"
                          onChange={handleChange}
                          autoComplete="off"
                        />{" "}
                        <input
                          className="m-2 border h-10 w-10 text-center form-control rounded focus:outline-none"
                          type="text"
                          id="sixth"
                          maxLength="1"
                          name="ssn-4"
                          onChange={handleChange}
                          autoComplete="off"
                        />{" "}
                      </div>
                      <button
                        className="w-10/12 ml-8 mt-6 bg-[#FB5B1D] rounded-2xl text-white py-3 text-xl font-semibold mb-12"
                        onClick={handleVerifyOTP}
                      >
                        Verify
                      </button>
                    </>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LoginModal;
