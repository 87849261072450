import React, { useState, useEffect } from 'react';
import {getAppConstants} from '../services/apiServices';

export const AppConstantsContext = React.createContext();

export const AppConstantsProvider = (props) => {
    const [appConstants, setappConstants] = useState({loading: true});

    useEffect(() => {
        const fn = async()=>{
            let res = await getAppConstants();
            if(res.statusCode===200){
                setappConstants({...res.payLoad, loading: false})
            }
        }
        fn();
    }, []);

    return (
        <AppConstantsContext.Provider value={appConstants}>
            {props.children}
        </AppConstantsContext.Provider>
    );
}
