import React, { useState, useEffect } from 'react';
import TagManager from 'react-gtm-module'

export const AnalyticsContext = React.createContext();

export const AnalyticsProvider = (props) => {
    const [host, sethost] = useState("")

    useEffect(() => {
        sethost(window.location.host);
        TagManager.initialize({
            gtmId: 'GTM-PP7VT95'
        })
    },[]);

    const gtmPageViewEvent = async(pathname, hostname) => {
        let ev = "virtual_page_view";
        if(hostname=="astroguru.bodhiness.com"){
            ev = ev+"_astroguru";
        }
        else if(hostname=="mobafl.bodhiness.com"){
            ev = ev+"_mobaf";
        }
        else if(hostname=="webj.bodhiness.com"){
            ev = ev+"_webj";
        }
        else if(hostname=="lokal.bodhiness.com"){
            ev = ev+"_lokal";
        }
        else if(hostname=="namah.bodhiness.com"){
            ev = ev+"_namah";
        }
        TagManager.dataLayer({
            dataLayer: {
                event: ev,
                page_path: pathname,
            },
        });
    }
 
    const gtmCustomEvent = async (eName, args, hostname) => {
        let ev = eName;
        if(host=="astroguru.bodhiness.com" || hostname=="astroguru.bodhiness.com"){
            ev = ev+"_astroguru";
        }
        else if(host=="mobafl.bodhiness.com" || hostname=="mobafl.bodhiness.com") {
            ev = ev+"_mobafl";
        }
        else if(host=="webj.bodhiness.com" || hostname=="webj.bodhiness.com"){
            ev = ev+"_webj";
        }
        else if(host=="lokal.bodhiness.com" || hostname=="lokal.bodhiness.com"){
            ev = ev+"_lokal";
        }
        else if(host=="namah.bodhiness.com" || hostname=="namah.bodhiness.com"){
            ev = ev+"_namah";
        }
        TagManager.dataLayer({
            dataLayer: {
                event: ev,
                ...args           
            }
        })
    }

    return (
        <AnalyticsContext.Provider value={{ gtmCustomEvent, gtmPageViewEvent }}>
            {props.children}
        </AnalyticsContext.Provider>
    )
}