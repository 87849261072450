import axios from "axios";

const API = axios.create({
  baseURL: process.env.GATSBY_API_BASE,
});

const httpHandler = async (url, requestType, headers, data) => {
  try {
    var res;
    let dc = localStorage.getItem('defaultCurrency') || 'inr';
    if(url.includes('?')){
      url = url+`&defaultCurrencyType=${dc}`;
    } else {
      url = url+`?defaultCurrencyType=${dc}`;
    }    
    switch (requestType) {
      case "GET":
        res = await API.get(url, { headers });
        break;
      case "POST":
        res = await API.post(url, data, { headers });
        break;
      case "PUT":
        res = await API.put(url, data, { headers });
        break;
      case "PATCH":
        res = await API.patch(url, data, { headers });
        break;
      case "DELETE":
        res = await API.delete(url, { headers });
        break;
      default:
        // eslint-disable-next-line no-throw-literal
        throw {
          response: {
            data: {
              statusCode: 400,
              message: "Unknown http request type!",
            },
          },
        };
    }
    if (res.status === 200 || res.status === 201) {
      return res.data;
    } else {
      // eslint-disable-next-line no-throw-literal
      throw {
        response: {
          data: {
            statusCode: 400,
            message:
              res?.data?.message ??
              "Unknown Error occurred! Contact tech team for support.",
          },
        },
      };
    }
  } catch (e) {
    return e?.response?.data;
  }
};

export { httpHandler };
