import React from 'react'
import gg from "../staticAssets/gg.gif";

function CallBeginAlertModal({data}) {
    return (
        <div className="fixed z-50 inset-0 overflow-y-auto worksans" aria-labelledby="modal-title" role="dialog" aria-modal="true">
            <div className="flex items-end justify-center min-h-screen pt-4 px-0 md:px-4 pb-20 text-center sm:block">

                <div className="fixed inset-0 bg-black bg-opacity-80 transition-opacity" aria-hidden="true"></div>
                <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>

                <div className="inline-block align-bottom bg-[#071324] rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle w-full md:w-2/3 lg:w-1/3">
                    
                            <div className="mt-3 text-center sm:text-left flex flex-col items-center w-full">
                                {/* <h3 className="text-lg leading-6 font-medium text-gray-900" id="modal-title">
                                    Deactivate account
                                </h3> */}
                                <div className="flex justify-center h-[282px]">

                                <img loading="lazy" src={gg} className="h-[282px] w-[282px] object-contain"/>
                                </div>
                                <h6 className="text-white text-2xl text-center">Wait a moment!</h6>
                                <span className="text-gray-300/70 text-center mx-2 mt-1 mb-6">You’ll get a call from astrologer shortly on {" "}<p className="inline-block">{data?.countryCode + " "+ data?.phoneNumber}</p></span>
                                
                    </div>
                    <div className="bg-[#071324] px-4 py-3 sm:px-6 flex justify-center w-full">
                        {/* <button type="button" className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm">
                            Deactivate
                        </button> */}
                        <button type="button" className="mt-3 w-1/3 inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-[#e7d6ff] text-base font-semibold text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:text-sm" onClick={()=>{data?.setalert(false);}}>
                            Ok
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CallBeginAlertModal
