import "./src/styles/global.css";
import React from "react";
import { HelmetProvider } from "react-helmet-async";
import { AnalyticsProvider } from "./src/context/Analytics";
import { AuthProvider } from "./src/context/Auth";
import { ConsultationProvider } from "./src/context/Consultation";
import Head from "./src/components/Head";
import "firebase/firestore";
import { AppConstantsProvider } from "./src/context/AppConstants";

const helmetContext = {};

export const wrapRootElement = ({ element }) => (
  <HelmetProvider context={helmetContext}>
    <AppConstantsProvider>
      <AnalyticsProvider>
        <AuthProvider>
          <ConsultationProvider>
            <Head />
            {element}
          </ConsultationProvider>
        </AuthProvider>
      </AnalyticsProvider>
    </AppConstantsProvider>
  </HelmetProvider>
);
