import React from 'react';
import { Helmet } from "react-helmet-async";

function Head() {

    const schemaOrgJSONLD = {"@context":"https://schema.org","@type":"Organization","name":"Bodhi","url":"https://www.bodhi.app/","logo":"https://www.bodhi.app/assets/img/bodhi/logo.png","contactPoint":{"@type":"ContactPoint","telephone":"+918130217308","contactType":"customer service","availableLanguage":"en"},"sameAs":["https://www.instagram.com/bodhiness/","https://www.linkedin.com/company/bodhiapp/","https://www.quora.com/profile/Bodhi-93/","https://www.youtube.com/channel/UCgfXfMCqazHrtMTFL2dLviA","https://www.bodhi.app/"]};

    return(
    <Helmet>
        <meta charset="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        
        <link rel="icon" type="image/png" href="bodhi-logo.jpeg"></link>
        <title>Free Horoscope Online Prediction 2024 | Astrology Services From Best Astrologer in India - Bodhi</title>
        <meta
            content="Get free online horoscope predictions 2024. Talk to Best astrologers in India for Kundli, Vastu, Tarot Card Reading, Numerology, Palm Reading, KP Astrology and more. Find trusted astrologers on Bodhi"
            name="description" />
        <meta
            content="Horoscope 2024, Horoscope In Hindi, Free Horoscope, Online Horoscope, Online Horoscope Matching, 2021 Horoscope, My Horoscope, Free Horoscope By Date Of Birth, Free Horoscope Online, Horoscope Generator, Horoscope Signs, Free Horoscope Matching, Free Horoscope Prediction, Horoscope By Name, Horoscope Matching By Date Of Birth, Horoscope Prediction, Kundali Horoscope, Birth Horoscope, Kundli Horoscope"
            name="keywords" />
        <meta name="facebook-domain-verification" content="suustljldtul245zjqwcplvwqmek4i" />
        <link rel="canonical" href="https://www.bodhi.app/" />
        <link href="https://www.bodhi.app/assets/img/bodhi/favicon.png" rel="icon" />
        <meta name="theme-color" content="#000000" />
        <script type="application/ld+json">
          {JSON.stringify(schemaOrgJSONLD)}
        </script>
        
    </Helmet>);
}

export default Head;
