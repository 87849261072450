import { httpHandler } from "./httpHandler";
// import { getCookie } from '../util/functions';
import axios from "axios";
const functions = require("../util/functions");

const headerWithAuth = () => {
  return {
    authorization: "Bearer " + localStorage.getItem("authToken"),
    "content-type": "application/json",
  };
};
const headerWithoutAuth = {
  "content-type": "application/json",
};

const headerOptionalAuth = () => {
  if (localStorage.getItem("authToken")) {
    return headerWithAuth();
  }
  return headerWithoutAuth;
};

const requestTypes = Object.freeze({
  GET: "GET",
  POST: "POST",
  PUT: "PUT",
  PATCH: "PATCH",
  DELETE: "DELETE",
});

const getCertificateDetail = async (id) => {
  let d = {
    id: id
  }
  return await httpHandler(`/v3/admin/learning/get-certificate`, requestTypes.POST, headerWithoutAuth, d);
};
const getAppConstants = async () => {
  let d = {
    buildNumber: 1001,
  };
  return await httpHandler(`/v1/users/appConstants`, requestTypes.POST, headerWithoutAuth, d);
};

const login = async (data) => {
  return await httpHandler("/v1/users/login", requestTypes.POST, headerWithoutAuth, data);
};

const verify = async (data) => {
  return await httpHandler("/v1/users/verify-login-otp", requestTypes.POST, headerWithoutAuth, data);
};

const getProfile = async () => {
  return await httpHandler(`/v1/users`, requestTypes.GET, headerWithAuth());
};

const getwidgets = async (category, page, limit, currency, sort, languages, price, specialization, defaultLanguages) => {
  let d = {
    category: [category],
  };
  if (languages && languages.length) {
    d.languages = languages;
  }
  if (specialization && specialization.length) {
    d.specialization = specialization;
  }
  if (price) {
    d.price = price;
  }
  if (defaultLanguages && defaultLanguages.length) {
    d.defaultLanguages = functions.processDefaultLanguages(defaultLanguages.split(","));
  }
  d.sort = sort;
  return await httpHandler(`/v1/astrologers/widget/${page}/${limit}`, requestTypes.POST, headerOptionalAuth(), d);
};

const getAstroDetail = async (id) => {
  return await httpHandler(`/v1/astrologers/detail/${id}`, requestTypes.GET, headerWithoutAuth);
};

const enterAmount = async () => {
  return await httpHandler(`/v1/payment/enterAmountNew`, requestTypes.GET, headerWithAuth());
};

const validateCoupon = async (code, amount, platform) => {
  let d = {
    amount: amount,
    platform: platform,
  };
  return await httpHandler(`/v1/coupon/${code}/validate`, requestTypes.POST, headerWithAuth(), d);
};

const getConsultationHistory = async (page, limit) => {
  return await httpHandler(`/v1/consultation/list/${page}/${limit}`, requestTypes.GET, headerWithAuth());
};

const getConsultationDetail = async (id) => {
  return await httpHandler(`/v1/consultation/${id}/details`, requestTypes.GET, headerWithAuth());
};

const startCall = async (astrologerId, host) => {
  let d = {
    astrologerId,
    platform: "WEB",
  };
  if (host == "astroguru.bodhiness.com") {
    d.platform = "ASTROGURU";
  } else if (host == "mobafl.bodhiness.com") {
    d.platform = "MOBAFL";
  } else if (host == "webj.bodhiness.com") {
    d.platform = "WEBJ";
  } else if (host == "lokal.bodhiness.com") {
    d.platform = "LOKAL";
  } else if (host == "namah.bodhiness.com") {
    d.platform = "NAMAH";
  }
  return await httpHandler(`/v1/consultation/initiateCall`, requestTypes.POST, headerWithAuth(), d);
};

const startChat = async (astrologerId, host) => {
  let d = {
    astrologerId,
    platform: "WEB",
  };
  if (host == "astroguru.bodhiness.com") {
    d.platform = "ASTROGURU";
  } else if (host == "mobafl.bodhiness.com") {
    d.platform = "MOBAFL";
  } else if (host == "webj.bodhiness.com") {
    d.platform = "WEBJ";
  } else if (host == "lokal.bodhiness.com") {
    d.platform = "LOKAL";
  } else if (host == "namah.bodhiness.com") {
    d.platform = "NAMAH";
  }
  return await httpHandler(`/v1/consultation/initiateChat`, requestTypes.POST, headerWithAuth(), d);
};

const endChat = async (id) => {
  return await httpHandler(`/v1/consultation/${id}/endChat`, requestTypes.GET, headerWithAuth());
};

const cancelChat = async (id) => {
  return await httpHandler(`/v1/consultation/${id}/cancelChat`, requestTypes.GET, headerWithAuth());
};

const createOrder = async (d) => {
  return await httpHandler("/v1/payment/createOrder", requestTypes.POST, headerWithAuth(), d);
};

const checkout = async (d) => {
  return await httpHandler(`/v1/payment/checkout`, requestTypes.POST, headerWithAuth(), d);
};

const sendQuery = async (d) => {
  return await httpHandler(`/v1/users/sendQuery`, requestTypes.POST, headerWithoutAuth, d);
};

const updateUser = async (d) => {
  return await httpHandler(`/v1/users`, requestTypes.POST, headerWithAuth(), d);
};

const glanceSMS = async (d) => {
  return await httpHandler(`/v1/users/glanceSendSMSLink`, requestTypes.POST, headerWithAuth(), d);
};

const getIpDetails = async () => {
  try {
    let response = await axios.get("https://ipapi.co/json/");
    return response.data;
  } catch (e) {
    console.error(e);
  }
};

const messageSent = async (id, message) => {
  return await httpHandler(`/v1/consultation/${id}/messageSent`, requestTypes.POST, headerWithAuth(), { message });
};

const addRating = async (rating, id) => {
  let d = { rating: rating };
  return await httpHandler(`/v1/consultation/${id}/addRating`, requestTypes.POST, headerWithAuth(), d);
};

const getContent = async (d) => {
  return await httpHandler(`/v1/contents/byIds`, requestTypes.POST, headerWithoutAuth, d);
};

const getTestimonials = async (page, limit) => {
  return await httpHandler(`/v1/testimonial/${page}/${limit}`, requestTypes.GET, headerWithoutAuth);
};

const getPaymentConfig = async (platform) => {
  return await httpHandler(`/v1/paymentConfig?platform=${platform}`, requestTypes.GET, headerWithAuth());
};

const places = async (d) => {
  return await httpHandler(`/v1/users/places`, requestTypes.POST, headerWithoutAuth, d);
};

const generateAccessToken = async (token) => {
  return await httpHandler(`/v1/users/generateAccessToken`, requestTypes.GET, {
    grant_token: token,
  });
};

const getPaymentDetails = async (d) => {
  return await httpHandler(`/v1/payment/paymentDetails`, requestTypes.POST, headerWithAuth(), d);
};

const getStaticPagesData = async (pageName) => {
  return await httpHandler(`/v1/static/pages/${pageName}`, requestTypes.GET, headerWithAuth());
};

export {
  getAppConstants,
  login,
  verify,
  getProfile,
  getwidgets,
  getAstroDetail,
  enterAmount,
  createOrder,
  validateCoupon,
  getConsultationHistory,
  getConsultationDetail,
  startCall,
  startChat,
  endChat,
  cancelChat,
  checkout,
  sendQuery,
  updateUser,
  glanceSMS,
  getIpDetails,
  messageSent,
  addRating,
  getContent,
  getTestimonials,
  getPaymentConfig,
  places,
  generateAccessToken,
  getPaymentDetails,
  getStaticPagesData,
  getCertificateDetail
};
