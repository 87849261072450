import React, { useState, useEffect, useContext } from "react";
import AlertModal from "../components/AlertModal";
import ErrorModal from "../components/ErrorModal";
import { cancelChat, endChat, getConsultationDetail, startCall, startChat } from "../services/apiServices";
import { navigate } from "gatsby";
import { AnalyticsContext } from "./Analytics";
import CallBeginAlertModal from "../components/CallBeginAlertModal";
import { AuthContext } from "./Auth";
import UserDetailModal from "../components/UserDetailModal";
import { AppConstantsContext } from "./AppConstants";

export const ConsultationContext = React.createContext();

export const ConsultationProvider = (props) => {
  const [callInitiated, setcallInitiated] = useState(false);
  const [consultationId, setconsultationId] = useState("");
  const [astrologerId, setastrologerId] = useState("");
  const [astrologerName, setastrologerName] = useState("");
  const [astroImgUrl, setastroImgUrl] = useState("");
  const [userId, setuserId] = useState("");
  const [userName, setuserName] = useState("");
  const [message, setmessage] = useState("");
  const [alert, setalert] = useState(false);
  const [callBeginAlert, setcallBeginAlert] = useState(false);
  const [consultUserDetailAlert, setconsultUserDetailAlert] = useState(false);
  const [error, seterror] = useState(false);
  const [fetchTrigger, setfetchTrigger] = useState(0);
  const [start, setstart] = useState("");
  const [cancelMins, setcancelMins] = useState(2);
  const [mode, setmode] = useState("");
  const { gtmCustomEvent } = useContext(AnalyticsContext) || {
    gtmCustomEvent: () => { },
  };
  const appConstants = useContext(AppConstantsContext) || {};
  const { user, update } = useContext(AuthContext) || {
    user: {},
    update: () => { },
  };
  const [sendInitMsg, setsendInitMsg] = useState(false);

  const [host, sethost] = useState("");
  const [chatStatus, setchatStatus] = useState("");

  const initiateCall = async (astroId, imgurl, byAlert) => {
    if (imgurl) {
      setastroImgUrl(imgurl);
    }
    if (astroId) {
      setastrologerId(astroId);
    }
    setmode("call");
    if (appConstants?.isUserDetailForCallActive && !byAlert) {
      setconsultUserDetailAlert(true);
    } else {
      let a = astroId ?? astrologerId;
      let res = await startCall(a, host);
      if (res.statusCode === 200) {
        if (imgurl) {
          setastroImgUrl(imgurl);
        }
        setconsultationId(res?.payLoad?.consultationDetail?._id);
        setmessage(res?.payLoad?.message);
        setcallBeginAlert(true);
        setastrologerId(res?.payLoad?.consultationDetail?.astrologer);
        setcallInitiated(true);
      } else {
        setmessage(res?.message);
        seterror(true);
      }
      return res?.payLoad?.astrologerDetail?.status;
    }
  };

  const initiateChat = async (astroId, imgurl, byAlert) => {
    try {
      if (imgurl) {
        setastroImgUrl(imgurl);
      }
      if (astroId) {
        setastrologerId(astroId);
      }
      setmode("chat");
      if (appConstants?.isUserDetailForChatActive && !byAlert) {
        setconsultUserDetailAlert(true);
      } else {
        let a = astroId ?? astrologerId;
        let res = await startChat(a, host);
        if (res.statusCode === 200) {
          setconsultationId(res?.payLoad?.consultationDetail?._id);
          localStorage.setItem("cId", res?.payLoad?.consultationDetail?._id);
          setuserId(res?.payLoad?.consultationDetail?.user?._id);
          setuserName(res?.payLoad?.consultationDetail?.user?.name);
          localStorage.setItem("consultationDetails", JSON.stringify(res?.payLoad?.consultationDetail));
          // setastrologerId(res?.payLoad?.consultationDetail?.astrologer?._id);
          setastrologerName(res?.payLoad?.consultationDetail?.astrologer?.name);
          setchatStatus(res?.payLoad?.consultationDetail?.status);
          setsendInitMsg(true);
          navigate("/chat", {
            replace: true,
          });
        } else {
          setmessage(res?.message);
          seterror(true);
        }
        return res?.payLoad?.astrologerDetail?.status;
      }
    } catch (e) { }
  };

  const endChatConsultation = async () => {
    if (!consultationId) {
      navigate("/consultAstro", { replace: true });
    }
    let res;

    if (chatStatus == "initiated") {
      res = await cancelChat(consultationId);
      if (res.statusCode === 200) {
        setconsultationId("");
        localStorage.removeItem("cId");
        setastrologerId("");
        setastrologerName("");
        setchatStatus("cancelled");
        setstart("");
        setmessage("Chat cancelled successfully!");

        setalert(true);
        navigate("/consultAstro", { replace: true });

      } else {
        if (res?.payLoad?.status == "started") {
          setchatStatus("started");
        }
        setmessage(res?.message);
        seterror(true);
      }
    } else {
      res = await endChat(consultationId);
      if (res.statusCode === 200) {
        setconsultationId("");
        localStorage.removeItem("cId");
        setastrologerId("");
        setastrologerName("");
        console.log(res?.payLoad?.consultationDetail?._id, "res?.payLoad?.consultationDetail?._id")
        navigate("/consultationEnd", {
          state: { id: res?.payLoad?.consultationDetail?._id },
          replace: true,
        });
        setchatStatus("completed");
      } else {
        console.log("false")
        console.log(res, "re")
        if (res?.payLoad.status == "started") {
          setchatStatus("started");
        }
        setmessage(res?.message);
        seterror(true);
      }
    }
  };

  const endChatConsultationByMessageSent = async (id, message) => {
    navigate("/consultationEnd", {
      state: { id: id },
      replace: true,
    });
    setconsultationId("");
    localStorage.removeItem("cId");
    setastrologerId("");
    setastrologerName("");
    setchatStatus("completed");
    if (message) {
      setmessage(message);
      seterror(true);
    }
  };

  useEffect(() => {
    sethost(window.location.host);
    const f = async (cId) => {
      let res = await getConsultationDetail(cId);
      if (res.statusCode === 200) {
        let s = res?.payLoad?.consultationDetail?.status;
        if (res?.payLoad?.consultationDetail?.mode == "chat" && (s == "initiated" || s == "started")) {
          setconsultationId(res?.payLoad?.consultationDetail?._id);
          setuserId(res?.payLoad?.consultationDetail?.user?._id);
          setuserName(res?.payLoad?.consultationDetail?.user?.name);
          setastrologerId(res?.payLoad?.consultationDetail?.astrologer?._id);
          setastrologerName(res?.payLoad?.consultationDetail?.astrologer?.name);
          if (s == "started") {
            setstart(res?.payLoad?.consultationDetail?.startTime);
          } else {
            setstart(res?.payLoad?.consultationDetail?.createdAt);
          }
          setcancelMins(res?.payLoad?.consultationDetail?.cancellationInMinutes);
          setchatStatus(s);
          navigate("/chat");
          // let end =
          //   new Date(res?.payLoad?.consultationDetail?.createdAt).getTime() +
          //   parseInt(res?.payLoad?.consultationDetail?.cancellationInMinutes) *
          //     60000;
          // let nw = new Date().getTime();
          // if (end - nw < 0) {
          // }
        } else {
          localStorage.removeItem("cId");
        }
      }
    };
    let cId = localStorage.getItem("cId");
    if (cId) {
      f(cId);
    }
  }, []);

  useEffect(() => {
    const fn = async () => {
      try {
        let res = await getConsultationDetail(consultationId);
        if (res.statusCode === 200) {
          let s = res?.payLoad?.consultationDetail?.status;
          if (s == "completed") {
            setconsultationId("");
            setastrologerId("");
            gtmCustomEvent("consult_end_page_view");
            navigate("/consultationEnd", {
              state: { id: res?.payLoad?.consultationDetail?._id },
              replace: true,
            });
            setcallInitiated(false);
          } else if (s == "cancelled") {
            setconsultationId("");
            setastrologerId("");
            // setmessage("Consultation was cancelled");
            // seterror(true);
            setcallInitiated(false);
          } else if (s == "failed") {
            setconsultationId("");
            setastrologerId("");
            // setmessage("Consultation failed. Please try again.");
            // seterror(true);
            setcallInitiated(false);
          } else if (s == "initiated" || s == "started") {
            if (s == "started") {
              setstart(res?.payLoad?.consultationDetail?.startTime);
            } else if (s == "initiated") {
              setstart(res?.payLoad?.consultationDetail?.createdAt);
              setcancelMins(res?.payLoad?.consultationDetail?.cancellationInMinutes);
            }
            if (res?.payLoad?.consultationDetail?.mode != "chat") {
              setTimeout(() => {
                setfetchTrigger(fetchTrigger + 1);
              }, 5000);
            }
          }
          if (res?.payLoad?.consultationDetail?.mode == "chat" && chatStatus != s) {
            setchatStatus(s);
          }
        }
      } catch (e) {
        console.error(e);
        setTimeout(() => {
          setfetchTrigger(fetchTrigger + 1);
        }, 5000);
      }
    };
    if ((callInitiated || chatStatus == "started" || chatStatus == "initiated") && !document.hidden) {
      fn();
    } else if (document.hidden) {
      setTimeout(() => {
        setfetchTrigger(fetchTrigger + 1);
      }, 5000);
    }
  }, [callInitiated, chatStatus, fetchTrigger]);

  const handleEndChatByAlert = async () => {
    try {
      await endChatConsultation();
    } catch (e) {
      console.error(e);
    }
  };

  const initiateByAlert = async (updateInfo) => {
    if (updateInfo) {
      await update(updateInfo);
    }
    if (mode == "chat") {
      await initiateChat(null, null, true);
    } else if (mode == "call") {
      await initiateCall(null, null, true);
    }
  };

  return (
    <div className="h-screen">
      <ConsultationContext.Provider
        value={{
          callInitiated,
          consultationId,
          initiateCall,
          astrologerId,
          initiateChat,
          endChatConsultation,
          userId,
          astrologerName,
          endChatConsultationByMessageSent,
          chatStatus,
          start,
          cancelMins,
          sendInitMsg,
          setsendInitMsg,
          handleEndChatByAlert,
          userName,
          setchatStatus,
          user,
          appConstants,
        }}
      >
        <>
          {alert && <AlertModal data={{ message, setalert }} />}
          {callBeginAlert && (
            <CallBeginAlertModal data={{ message, setalert: setcallBeginAlert, countryCode: user?.countryCode, phoneNumber: user?.phoneNumber }} />
          )}
          {error && <ErrorModal data={{ message, seterror }} />}
          {consultUserDetailAlert && <UserDetailModal data={{ setalert: setconsultUserDetailAlert, astroImgUrl, initiateByAlert, user }} />}
          {props.children}
        </>
      </ConsultationContext.Provider>
    </div>
  );
};
