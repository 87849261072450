import React, { useState, useEffect, useContext } from "react";
import LoginModal from "../components/LoginModal";
import ErrorModal from "../components/ErrorModal";
import {
  login,
  getProfile,
  verify,
  updateUser,
  getIpDetails,
  generateAccessToken,
} from "../services/apiServices";
import { AnalyticsContext } from "./Analytics";

export const AuthContext = React.createContext();

export const AuthProvider = (props) => {
  const { gtmCustomEvent } = useContext(AnalyticsContext) || {
    gtmCustomEvent: () => {},
  };
  const [user, setuser] = useState({ _id: "", currencyType: 'inr', countryacode:'+91' });
  const [showLogin, setshowLogin] = useState(false);
  const [host, sethost] = useState("");
  const [ipDetails, setipDetails] = useState({ country: "IN" });
  const [message, setmessage] = useState("");
  const [error, seterror] = useState(false);

  useEffect(() => {
    sethost(window.location.host);
    const fn = async () => {
      try {
        let ipDetails = await getIpDetails();
        let currencyType = (ipDetails?.country && ipDetails?.country !== "IN") ? "usd" : "inr";
        if (ipDetails) {
          let ob = {
            currencyType: currencyType,
            countryCode: ipDetails?.country_calling_code,
          };
          setuser({ ...ob });
          setipDetails({ ...ipDetails });
        }
        localStorage.setItem("defaultCurrency",currencyType);
        if (window.location.host == process.env.GATSBY_LOKAL_BASE || window.location.host == process.env.GATSBY_NAMAH_BASE) {
          let token = document.cookie
            .split("; ")
            .find((row) => row.startsWith("grant="))
            ?.split("=")[1];
          console.log("token: ", token);
          let resp = await generateAccessToken(token);
          if (resp.statusCode === 200) {
            localStorage.setItem("authToken", resp.payLoad.accessToken);
            setuser({ ...resp.payLoad });
          } else {
            // TODO: android interface
            localStorage.removeItem("authToken");
          }
        } else {
          if (localStorage.getItem("authToken")) {
            fetchUser();
          } 
        }
      } catch (e) {
        console.error(e);
        let ob = {
          currencyType: "inr",
        };
        let ipDetails = {
          country: "IN",
          country_calling_code: "+91",
        };
        setipDetails({ ...ipDetails });
        setuser({ ...ob });
      }
    };
    fn();
  }, []);

  const fetchUser = async () => {
    try {
      let res = await getProfile();
      if (res.statusCode === 200) {
        setuser({ ...res.payLoad });
      } else {
        localStorage.removeItem("authToken");
      }
    } catch (e) {
      console.error(e);
    }
  };

  const loginUser = async (countryCode, phoneNumber, notifyByWhatsapp) => {
    let d = {
      countryCode,
      phoneNumber,
      notifyByWhatsapp,
      platform: "WEB",
      onlyUserLogin: true,
    };

    if (host == "astroguru.bodhiness.com") {
      d.platform = "ASTROGURU";
    } else if (host == "mobafl.bodhiness.com") {
      d.platform = "MOBAFL";
    } else if (host == "webj.bodhiness.com") {
      d.platform = "WEBJ";
    } else if (host == "lokal.bodhiness.com") {
      d.platform = "LOKAL";
    } else if (host == "namah.bodhiness.com") {
      d.platform = "NAMAH";
    }
    let res = await login(d);
    if (res.statusCode == 200) {
      return true;
    } else {
      setmessage(res?.message);
      seterror(true);
    }
  };

  const verifyUser = async (countryCode, phoneNumber, OTP) => {
    let d = {
      countryCode,
      phoneNumber,
      verificationCode: OTP,
    };
    let res = await verify(d);
    if (res.statusCode == 200) {
      localStorage.setItem("authToken", res?.payLoad?.accessToken);
      setuser({ ...res.payLoad });
      gtmCustomEvent("sign_up");
      if (res.payLoad.name && res.payLoad.gender) {
        setshowLogin(false);
      }
      return true;
    } else {
      return false;
    }
  };

  const update = async (d) => {
    if (d.dob) {
      let dob = new Date(d.dob);
      dob = [dob.getMonth() + 1, dob.getDate(), dob.getFullYear()].join("-");
      d.dob = dob;
    }
    let res = await updateUser(d);
    if (res.statusCode == 200) {
      setuser({ ...res.payLoad });
    }
  };

  const signOut = () => {
    localStorage.removeItem("authToken");
    let ob = {
      currencyType: ipDetails?.country == "IN" ? "inr" : "usd",
      countryCode: ipDetails?.country_calling_code,
    };
    setuser({ ...ob });
  };

  return (
    <AuthContext.Provider
      value={{ user, fetchUser, setshowLogin, signOut, update }}
    >
      {showLogin && (
        <LoginModal
          setshowLogin={setshowLogin}
          loginUser={loginUser}
          verifyUser={verifyUser}
          update={update}
          ipDetails={ipDetails}
        />
      )}
      {error && <ErrorModal data={{ message, seterror }} />}
      {user?.currencyType && props.children}
    </AuthContext.Provider>
  );
};
