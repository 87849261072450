const saveAuthData = (authToken, p91AuthToken) => {
    if (authToken){
        localStorage.setItem('authToken', authToken);
    }
    if (p91AuthToken){
        localStorage.setItem('p91AuthToken', p91AuthToken);
    }
}


function capitalize(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

function capitalizeFirstLetter(input) {  
    var words = input.split(' ');  
    var CapitalizedWords = [];  
    words.forEach(element => {  
        CapitalizedWords.push(element[0].toUpperCase() + element.slice(1, element.length));  
    });  
    return CapitalizedWords.join(' ');  
}  

const processLanguage = (languages) => {
    if (!languages || !languages.length) {
        return " ";
    }
    let s = capitalize(languages[0]);
    for (let i = 1; i < languages.length; i++) {
        s = s + ', ' + capitalize(languages[i]);
    }
    return s;
}

const getCookie = (key) => {
    var cookies = document.cookie.split(';').reduce(
        (cookies, cookie) => {
            const [name, val] = cookie.split('=').map(c => c.trim());
            cookies[name] = val;
            return cookies;
        }, {});
    return cookies[key];
}

const createAndPostForm = (path, params, method = 'post') => {
    const form = document.createElement('form');
    form.method = method;
    form.action = path;

    for (const key in params) {
        if (params.hasOwnProperty(key)) {
            const hiddenField = document.createElement('input');
            hiddenField.type = 'hidden';
            hiddenField.name = key;
            hiddenField.value = params[key];

            form.appendChild(hiddenField);
        }
    }
    // // Create a submit button
    // var s = document.createElement("input");
    // s.setAttribute("type", "submit");
    // s.setAttribute("value", "Submit");
    // form.appendChild(s);
    
    document.body.appendChild(form);
    form.submit();
}

const onlyDate = (t) => {
    if (t === "" || t === null) {
        return "";
    }
    let timestamp = new Date(Date.parse(t));
    timestamp =
        timestamp.getDate() +
        "." +
        (timestamp.getMonth() + 1) +
        "." +
        timestamp.getFullYear();
    return timestamp;
};

const onlyTime = (t) => {
    if (t === "" || t === null) {
        return "";
    }
    let date = new Date(Date.parse(t));
    let hours = date.getHours();
    let minutes = date.getMinutes();
    let ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0' + minutes : minutes;
    let strTime = hours + ':' + minutes + ' ' + ampm;
    return strTime;
}

const processDefaultLanguages = (defaultLanguages) => {
    let langs = {
        'hi': "hindi",
        'en': "english",
        'ta': "tamil",
        'ml': "malayalam",
        'kn': "kannada",
        'pa': "punjabi",
        'bn': "bengali",
        'mr': "marathi",
        'gu': "gujarati",
        'te': "telugu"
    }
    let ls = [];
    for (let i = 0; i < defaultLanguages.length; i++) {
        if(langs[defaultLanguages[i]]){
            ls.push(langs[defaultLanguages[i]]);
        }
    }
    return ls;
}

export {
    processLanguage,
    capitalize,
    getCookie,
    createAndPostForm,
    onlyDate,
    onlyTime,
    saveAuthData,
    capitalizeFirstLetter,
    processDefaultLanguages
}